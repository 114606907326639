import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/Layout'
import Breadcrumbs from '../components/Breadcrumbs'

function ContactPage({ data }) {
  const currentPage = data.wordpressPage
  const { slug, title, content, acf } = currentPage
  const imagePosition = { objectPosition: `50% 35%` }
  return (
    <Layout location={slug} pageTitle={title}>
      <div className="hero-outer">
        <div className="overlay">
          <Image
            className="hero-inner"
            fluid={acf.hero_image.localFile.childImageSharp.fluid}
            imgStyle={imagePosition}
          />
        </div>
        <div className="title-page-container fade-in">
          <h2
            className="title-page"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      </div>
      <div className="container">
        <Breadcrumbs title={title} />
        <div
          className="font-body"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "contact" }) {
      slug
      title
      content
      excerpt
      acf {
        hero_image {
          id
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
